<template>
   <YLayoutBodyMenu :menu="[
       {title:'基础配置',path:'/shop/plugin/cmo/conf'},
       {title:'总监管理',path:'/shop/plugin/cmo/manage'},
   ]">
     <router-view/>
   </YLayoutBodyMenu>
</template>

<script>
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";
export default {
  name: "index",
  components: {YLayoutBodyMenu},
}
</script>

<style scoped>

</style>